import { ClassData } from '@models/class-data';

export class EventTemplateInvitationCard {
  id?: number;

  title: string;
  invitationText: string;

  contactName: string;
  contactEmail: string;
  contactPhone: string;

  constructor(json: ClassData<EventTemplateInvitationCard>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }

    this.title = json.title;
    this.invitationText = json.invitationText;

    this.contactName = json.contactName;
    this.contactEmail = json.contactEmail;
    this.contactPhone = json.contactPhone;
  }
}
