import { ClassData } from '@models/class-data';

export class EventTemplateFont {
  id?: number;

  primaryLogoFont: string;
  primaryLogoFontColor: string;
  primaryFontWeight?: number;
  bodyFont: string;
  bodyFontColor: string;
  bodyFontWeight?: number;
  // signalFont: string;
  signalFontColor?: string;
  // signalFontWeight?: number;

  // weddingpage colors
  sectionHeadlineColor?: string;
  sectionBodyColor?: string;
  sectionBackgroundColor?: string;

  sectionAccentHeadlineColor?: string;
  sectionAccentBodyColor?: string;
  sectionAccentBackgroundColor?: string;

  constructor(json: ClassData<EventTemplateFont>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }

    this.primaryLogoFont = json.primaryLogoFont;
    this.primaryLogoFontColor = json.primaryLogoFontColor;
    if (json.primaryFontWeight) {
      this.primaryFontWeight = +json.primaryFontWeight;
    }

    this.bodyFont = json.bodyFont;
    this.bodyFontColor = json.bodyFontColor;
    if (json.bodyFontWeight) {
      this.bodyFontWeight = +json.bodyFontWeight;
    }

    // this.signalFont = json.signalFont;
    this.signalFontColor = json.signalFontColor;
    // if (json.signalFontWeight) {
    //   this.signalFontWeight = +json.signalFontWeight;
    // }

    this.sectionHeadlineColor = json.sectionHeadlineColor;
    this.sectionBodyColor = json.sectionBodyColor;
    this.sectionBackgroundColor = json.sectionBackgroundColor;

    this.sectionAccentHeadlineColor = json.sectionAccentHeadlineColor;
    this.sectionAccentBodyColor = json.sectionAccentBodyColor;
    this.sectionAccentBackgroundColor = json.sectionAccentBackgroundColor;
  }
}
