import { ClassData } from '@models/class-data';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventSponsor } from '@models/events/event-sponsor.model';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { User } from '@models/users/user.model';
import { EventTemplateAccess } from './event-template-access.enum';
import { EventTemplateRelevance } from './event-template-relevance.enum';
import { Address } from '@models/shared/address.model';
import { EventTemplateVisual } from './event-template-visual.model';
import { EventTemplateFont } from './event-template-font.model';
import { EventWidget } from '@models/events/event-widget.enum';
import { EventTemplateInvitationCard } from './event-template-invitation-card.model';
import { Kit } from '@models/pricing-plans/kit.enum';
import { EventTemplateAffiliateProduct } from './event-template-affiliate-product.model';
import { EventTemplateSeo } from './event-template-seo.model';
import { Keyword } from '@models/shared/keyword.model';
import { EventAffiliateProductType } from '@models/events/event-affiliate-product-type.enum';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { EventTemplateCreationRequest } from './dto/event-template-creation.request';
import { createDataUrl } from '@models/shared/asset.model';
import { environment as env } from '@environments/environment';
import { EventCreationRequest } from '@models/events/dto/event-creation.request';
import { EventType } from '@models/events/event-type.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventTimetable } from '@models/events/event-timetable.model';
import { EventWeddingParty } from '@models/events/event-wedding-party.model';
import { EventFaq } from '@models/events/event-faq.model';
import { EventTemplateMarketplaceVisual } from './event-template-marketplace-visual.model';

export class EventTemplate {
  id: number;
  createdAt?: Date;

  type: EventType;

  originalId?: number;

  creatorId: number;
  creator?: User;

  uri: string;

  name: string;

  // event info
  description: string;
  eventName: string;
  eventCta: string;

  access: EventTemplateAccess;
  relevance: EventTemplateRelevance;

  shownOnSite: boolean;

  eventCategories?: EventCategory[];
  eventSubcategories?: EventCategory[];

  startDate?: Date;
  endDate?: Date;
  timeZone?: string;

  promotionStartDate?: Date;
  promotionEndDate?: Date;
  promotionTimeZone?: string;

  targetGroups?: number[];

  visualId: number;
  visual?: EventTemplateVisual;

  fontId: number;
  font?: EventTemplateFont;

  widgets?: EventWidget[];
  kits?: Kit[];

  partnerId?: number;
  partner?: User;

  affiliateProducts?: EventTemplateAffiliateProduct[];

  eventAddressId: number;
  eventAddress?: Address;

  standardInvitationText: string;

  invitationCardId: number;
  invitationCard?: EventTemplateInvitationCard;

  seoId: number;
  seo?: EventTemplateSeo;

  eventSections?: EventSection[];

  eventTimetableId?: number;
  eventTimetable?: EventTimetable;

  eventWeddingPartyId?: number;
  eventWeddingParty?: EventWeddingParty;

  eventFaqs?: EventFaq[];

  keywords?: Keyword[];

  eventTemplateStats: any;
  eventSponsors?: EventSponsor[];
  eventTestimonials?: EventTestimonial[];

  constructor(json: ClassData<EventTemplate>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.type = json.type;

    if (json.originalId !== undefined) {
      this.originalId = +json.originalId;
    }

    this.creatorId = +json.creatorId;
    if (json.creator) {
      this.creator = new User(json.creator);
    }

    this.uri = json.uri;

    this.name = json.name;

    this.description = json.description;
    this.eventName = json.eventName;
    this.eventCta = json.eventCta;

    this.access = json.access;
    this.relevance = json.relevance;

    this.shownOnSite = !!json.shownOnSite;

    if (json.eventCategories) {
      this.eventCategories = this.mapEventCategories(json.eventCategories);
    }
    if (json.eventSubcategories) {
      this.eventSubcategories = this.mapEventCategories(
        json.eventSubcategories,
      );
    }
    if (json.eventSponsors) {
      this.eventSponsors = this.mapEventSponsors(json.eventSponsors);
    }
    if (json.eventTestimonials) {
      this.eventTestimonials = this.mapEventTestimonials(
        json.eventTestimonials,
      );
    }
    if (json.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }
    this.timeZone = json.timeZone;

    if (json.promotionStartDate) {
      this.promotionStartDate = new Date(json.promotionStartDate);
    }
    if (json.promotionEndDate) {
      this.promotionEndDate = new Date(json.promotionEndDate);
    }
    this.promotionTimeZone = json.promotionTimeZone;

    if (json.targetGroups) {
      this.targetGroups = json.targetGroups;
    }

    this.visualId = +json.visualId;
    if (json.visual) {
      this.visual = new EventTemplateVisual(json.visual);
    }

    this.fontId = +json.fontId;
    if (json.font) {
      this.font = new EventTemplateFont(json.font);
    }

    this.widgets = json.widgets;
    this.kits = json.kits;

    if (json.partnerId !== undefined) {
      this.partnerId = +json.partnerId;
    }
    if (json.partner) {
      this.partner = new User(json.partner);
    }

    if (json.affiliateProducts) {
      this.affiliateProducts = this.mapEventTemplateAffiliateProducts(
        json.affiliateProducts,
      );
    }

    this.eventAddressId = +json.eventAddressId;
    if (json.eventAddress) {
      this.eventAddress = new Address(json.eventAddress);
    }

    this.standardInvitationText = json.standardInvitationText;

    this.invitationCardId = +json.invitationCardId;
    if (json.invitationCard) {
      this.invitationCard = new EventTemplateInvitationCard(
        json.invitationCard,
      );
    }

    this.seoId = +json.seoId;
    if (json.seo) {
      this.seo = new EventTemplateSeo(json.seo);
    }

    if (json.eventSections) {
      this.eventSections = this.mapEventSections(json.eventSections);
    }

    if (json.eventTimetableId) {
      this.eventTimetableId = +json.eventTimetableId;
    }
    if (json.eventTimetable) {
      this.eventTimetable = new EventTimetable(json.eventTimetable);
    }

    if (json.eventWeddingPartyId) {
      this.eventWeddingPartyId = +json.eventWeddingPartyId;
    }
    if (json.eventWeddingParty) {
      this.eventWeddingParty = new EventWeddingParty(json.eventWeddingParty);
    }

    if (json.eventFaqs) {
      this.eventFaqs = this.mapEventFaqs(json.eventFaqs);
    }

    if (json.keywords) {
      this.keywords = json.keywords;
    }

    this.eventTemplateStats = json.eventTemplateStats;
  }

  getAfterAffiliateProducts(
    type: EventAffiliateProductType,
  ): AffiliateProduct[] {
    if (!this.affiliateProducts) {
      return [];
    }
    const afterCreationAffiliateProducts = this.affiliateProducts.filter(
      (product) => !!product.affiliateProduct && product.type === type,
    );

    return afterCreationAffiliateProducts.map(
      (product) => product.affiliateProduct!,
    );
  }

  getLink(): string {
    return `${env.website}/design-templates/${this.uri}`;
  }

  getEventCreationRequest(): EventCreationRequest {
    return {
      eventTemplateId: this.id,
      name: this.eventName,
    };
  }

  getEventSectionsOrdered(): EventSection[] | undefined {
    return this.eventSections?.sort((a, b) => a.order - b.order);
  }

  getEventSectionByType(type: EventSectionType): EventSection | undefined {
    return this.eventSections?.find((section) => section.type === type);
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }

  private mapEventTemplateAffiliateProducts(
    products: EventTemplateAffiliateProduct[],
  ): EventTemplateAffiliateProduct[] {
    return products.map((it) => new EventTemplateAffiliateProduct(it));
  }

  private mapEventSections(sections: EventSection[]): EventSection[] {
    return sections.map((it) => new EventSection(it));
  }

  private mapEventSponsors(eventSponsors: EventSponsor[]): EventSponsor[] {
    return eventSponsors.map((sponsor) => new EventSponsor(sponsor));
  }

  private mapEventTestimonials(
    eventTestimonials: EventTestimonial[],
  ): EventTestimonial[] {
    return eventTestimonials.map(
      (testimonial) => new EventTestimonial(testimonial),
    );
  }

  private mapEventFaqs(faqs: EventFaq[]): EventFaq[] {
    return faqs.map((it) => new EventFaq(it));
  }
}

export function mapCreationRequestToEventTemplate(
  req: EventTemplateCreationRequest,
): EventTemplate {
  return new EventTemplate({
    id: 0,

    type: EventType.PARTYPAGE,

    creatorId: 0,

    uri: req.uri,

    name: req.name,

    description: req.description,
    eventName: req.eventName,
    eventCta: req.eventCta,

    access: req.access,
    relevance: req.relevance,

    shownOnSite: req.shownOnSite,

    startDate: req.startDate,
    endDate: req.endDate,
    timeZone: req.timeZone,

    promotionStartDate: req.promotionStartDate,
    promotionEndDate: req.promotionEndDate,
    promotionTimeZone: req.promotionTimeZone,

    targetGroups: req.targetGroupIds,

    visualId: 0,
    visual: new EventTemplateVisual({
      id: 0,
      thumbnailUrl: createDataUrl(req.visual.thumbnailAsset),
      logoUrl: req.visual.logoAsset
        ? createDataUrl(req.visual.logoAsset)
        : undefined,
      keyVisual1Id: req.visual.keyVisual1Id,
      keyVisual1: req.visual.keyVisual1,
      keyVisual2Url: req.visual.keyVisual2Asset
        ? createDataUrl(req.visual.keyVisual2Asset)
        : undefined,
      patternUrl: req.visual.patternAsset
        ? createDataUrl(req.visual.patternAsset)
        : undefined,
      backgroundId: req.visual.backgroundId,
      background: req.visual.background,
      backgroundColor: req.visual.backgroundColor,
      footerColor: req.visual.footerColor,

      effectId: req.visual.effectId,
      effect: req.visual.effect,

      marketplaceVisuals: req.visual.marketplaceVisuals
        ? req.visual.marketplaceVisuals.map(
            (asset) =>
              new EventTemplateMarketplaceVisual({
                url: createDataUrl(asset),
                title: asset.title || '',
                alt: asset.alt || '',
              }),
          )
        : [],
    }),

    fontId: 0,
    font: new EventTemplateFont(req.font),

    widgets: req.widgets,
    kits: req.kits,

    partnerId: req.partnerId,

    eventAddressId: 0,
    eventAddress: req.eventAddress ? new Address(req.eventAddress) : undefined,

    standardInvitationText: req.standardInvitationText,

    invitationCardId: 0,
    invitationCard: new EventTemplateInvitationCard(req.invitationCard),

    seoId: 0,
    seo: new EventTemplateSeo({
      id: 0,

      sharingPictureUrl: req.seo.sharingPictureAsset
        ? createDataUrl(req.seo.sharingPictureAsset)
        : '',
      title: req.seo.title,
      description: req.seo.description,
      pageDetailSummary: req.seo.pageDetailSummary,
      pageDetail: req.seo.pageDetail,
    }),

    eventTemplateStats: {},
  });
}
